.css-x3w4s2-MuiBadge-badge {
  width: 140px !important;
  -webkit-transform: scale(1) translate(-20%, -50%) !important;
  border-radius: 5px !important;
}

.css-1xrpcem-MuiBadge-badge {
  transform: scale(1) translate(-330%, -50%) !important;
  // transform: scale(1) translate(-180%, -50%) !important;
}

/* .css-46bh2p-MuiCardContent-root:last-child {
  min-width: 424px !important;
}

.css-b326aq {
  min-width: 428px;
} */

.top-badge {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.75rem;
  min-width: 100px;
  //   min-width: 20px;
  line-height: 1;
  padding: 0 6px;
  height: 20px;
  border-radius: 5px;
  //   border-radius: 10px;
  z-index: 1;
  -webkit-transition: -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #303f9f;
  color: #fff;
  top: 0;
  right: 0;
  -webkit-transform: scale(1) translate(-300%, -50%);
  -moz-transform: scale(1) translate(-300%, -50%);
  -ms-transform: scale(1) translate(-300%, -50%);
  transform: scale(1) translate(-300%, -50%);
  transform-origin: 100% 0%;

  // -webkit-transform: scale(1) translate(50%, -50%);
  // -moz-transform: scale(1) translate(50%, -50%);
  // -ms-transform: scale(1) translate(50%, -50%);
  // transform: scale(1) translate(50%, -50%);
}


.recommended {
  border-radius: 6rem;
  color: #FFFFFFFF;
  padding-left: 0.5rem;
  padding-bottom: 0.2rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  font-size: 0.8rem;
  width: 6.5rem;  

}

.economise {
  border-radius: 1rem;
  background-color: #ff9900;
  color: #FFFFFFFF;
   padding-left: 0.5rem;
  padding-bottom: 0.2rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  font-size: 0.8rem;
  min-width:9rem;
}


/*  @media screen and (max-width: 1700px) {
  .recommended {
    position: absolute;
    top: -1rem;
    display: inline;
    border-radius: 8rem;
    background-color: #302987;
    color: #FFFFFFFF;
    padding-left: 0.5rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
    font-size: 0.8rem;
    width: 6.5rem;
  }

  .economise {
    position: absolute;
    left: 2rem;
    top: -1rem;
    margin-left: 12rem;
    display: inline;
    border-radius: 1rem;
    background-color: #ff9900;
    color: #FFFFFFFF;
    padding-left: 0.5rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
    font-size: 0.8rem;
    width: 7rem;
  }
} 



@media screen and (max-width: 1600px) {
  .economise {
    position: absolute;
    left: 2rem;
    top: -1rem;
    margin-left: 9rem;
    display: inline;
    border-radius: 1rem;
    background-color: #ff9900;
    color: #FFFFFFFF;
    padding-left: 0.5rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
    font-size: 0.8rem;
    width: 7rem;
  }
} 

@media screen and (max-width: 1400px) {
  .economise {
    position: absolute;
    left: 2rem;
    top: -1rem;
    margin-left: 5rem;
    display: inline;
    border-radius: 1rem;
    background-color: #ff9900;
    color: #FFFFFFFF;
    padding-left: 0.5rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
    font-size: 0.8rem;
    width: 7rem;
  }
} 


@media screen and (max-width: 1200px) {
  .recommended {
    position: absolute;
    top: -1rem;
    display: inline;
    border-radius: 6rem;
    background-color: #302987;
    color: #FFFFFFFF;
    padding-left: 0.5rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
    font-size: 0.8rem;
    width: 6.5rem;
  }

  .economise {
    position: absolute;
    left: 2rem;
    top: -1rem;
    margin-left: 5rem;
    display: inline;
    border-radius: 1rem;
    background-color: #ff9900;
    color: #FFFFFFFF;
    padding-left: 0.5rem;
    padding-bottom: 0.2rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
    font-size: 0.8rem;
    width: 7rem;
  }
}  */

