.conversation-list {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  .btn-new-chat {
    height: 50px;
  }
  .list-item {
    flex: 1 1 auto;
    max-height: calc(100% - 265px);
    // overflow: hidden;
    overflow: auto !important;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 0.2em;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    // &:hover {
    //   overflow: auto !important;
    // }
  }
  .footer-box {
    height: 50px;
    padding-bottom: 10px;
  }
}

.conversation-screen {
  display: flex;
  flex-direction: column;
  height: 100%;

  .conversation-app-bar {
    height: 60px;
  }
  .content-conversation {
    flex: 1 1 auto;
    .wrap-chat {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 15px;
      scrollbar-width: "thin";
      .message-box {
        padding-left: 8px;
        padding-right: 8px;
        .content-message {
          max-width: 60%;
          width: max-content;
          padding-top: 5px;
          padding-bottom: 5px;
          border-radius: 10px;
          padding-left: 10px;
          padding-right: 10px;
          //background-color: white;
          display: flex;
          align-items: center;
          box-shadow: none;
          position: relative;
          .triangle-left {
            position: absolute;
            content: '""';
            display: "block";
            top: 0px;
            width: 0;
            height: 0;
            left: -8px;
            //border-top: 15px solid white;
            border-left: 15px solid transparent;
          }
        }
        .css-bhp9pd-MuiPaper-root-MuiCard-root {
          box-shadow: none !important;
        }

        .response-message {
          background-color: #303f9f;
          color: white;
          .triangle-right {
            position: absolute;
            content: '""';
            display: "block";
            top: 0px;
            width: 0;
            height: 0;
            right: -8px;
            border-top: 15px solid #303f9f;
            border-right: 15px solid transparent;
          }
        }
        .faild-message {
          background-color: transparent !important;
          box-shadow: none;
          border-left: 2px solid #f08080;
          border-radius: 0;
          padding-top: 0px;
          padding-bottom: 0px;
        }
        .success-evaluation {
          background-color: #2e7d32;
          color: white;
        }
        .correction-message-open {
          background-color: #2e7d32;
        }
        .correction-message-choice {
          background-color: #2e7d32;
          display: block;
        }
        .wrap-options-evaluation {
          padding-top: 5px;
          padding-bottom: 5px;
          display: flex;
          gap: 4px;
          flex-direction: column;
          max-width: 50%;
          //background-color: red;
          .option-evaluation {
            //border: 1px solid rgba(0, 0, 0, 0.3);
            //background-color: white;
            border-radius: 10px;
            padding: 5px 10px;
            text-transform: none;
            text-align: left;
            justify-content: flex-start;
            //color:#303F9F;
          }
        }
      }
    }
    position: relative;
    .absolute-bg {
      //background-color: rgba(26, 27, 33, .7);
      // background-color: rgba(14, 14, 15, 0.9);
      // background-color: #1A1D21;
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
    }
  }
  .wrap-conversation-footer {
    height: 100%;
    width: 100%;
    position: relative;
    .absolute-bg {
      //background-color: rgba(26, 27, 33, .7);
      //background-color: #0b0b0b;
      //background-color: rgba(14, 14, 15, 0.9);
      // background-color: #1A1D21;
      position: absolute;
      left: 0;
      right: 0;
      height: 100%;
    }
    .conversation-footer {
      height: 100px;
      .copy-right {
        font-size: 12px;
        letter-spacing: 0.5px;
        //color: rgba(0, 0, 0, 0.6);
        //color:#d32f2f;
        font-weight: 400;
        z-index: 999;
      }
      .subscription-box {
        width: 100%;
        //border:1px solid red;
        text-align: center;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
        .content-subscription {
          width: 100%;
        }
      }
    }
  }
  .wresponse-box {
    .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
      opacity: 1;
      -webkit-text-fill-color: inherit;
    }
  }
}

.css-h4y409-MuiList-root {
  padding-top: 0 !important;
}
.css-1f8bwsm {
  min-width: 0px !important;
}