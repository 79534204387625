.subscription-list {
    display: flex;
    flex-direction: column;
    height: calc(100%);
    .list-item {
      flex: 1 1 auto;
      max-height: calc(100%);
      padding-left: 8px;
      padding-right: 8px;
      overflow: hidden;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 0.2em;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      &:hover {
        overflow: auto !important;
      }
    }
  }


.styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    width: 100%;
    margin-bottom: 25px;
    font-weight: 300;
    font-family: "nunito";
}

.styled-table thead tr {
    background-color: #3f51b5;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 8px 5px 8px 0px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #b4aeae;
}

.styled-table tbody tr:nth-of-type(odd) {
  background-color: #e9ecef;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #fff;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #d4d4df;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}


.styled-table-dark{
  border-collapse: collapse;
  font-size: 0.9em;
  width: 100%;
  margin-bottom: 25px;
  font-weight: 300;
  font-family: "nunito";
}

.styled-table-dark thead tr {
  background-color: #3f51b5;
  color: #ffffff;
  text-align: left;
}

.styled-table-dark th,
.styled-table-dark td {
  padding: 8px 5px 8px 0px;
}

.styled-table-dark tbody tr {
  border-bottom: 1px solid #b4aeae;
}

.styled-table-dark tbody tr:nth-of-type(odd) {
background-color: #b8b5b5;
}

.styled-table-dark tbody tr:nth-of-type(even) {
  background-color: #495057;
  color:#ffffff;
}

.styled-table-dark tbody tr:last-of-type {
  border-bottom: 2px solid #d4d4df;
}

.styled-table-dark tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
