.wrap-apropos {
  //height: 100%;

  box-sizing: border-box;
  // user-select: none;
  // padding-bottom: 0.5em;
  // padding-top: 0.5rem;
  // padding-left: 1rem;
  // padding-right: 1rem;

  h2 {
    font-weight: 700 !important;
  }
  h3 {
    margin-top: 3px;
  }
  ul {
    padding-left: 2rem;
  }
  p {
    font-weight: initial !important;
    // line-height: 1.8;
    margin-top: 5px;
  }
  strong {
    margin-top: 4rem !important;
  }
}

.box-apropos {
  overflow: auto !important;
  scrollbar-width: thin;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 0.2em;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &:hover {
    overflow: auto !important;
  }
}
