.display-box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  .toolbar {
    border: "1px solid red";
    //background-color: #303f9f;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 20px;
    //color: white;
    height: 90px;
  }
  .content-display-box {
    flex: 1 1 auto;
    height: calc(100vh - 90px);
    //overflow: hidden;		
    scrollbar-width: thin;
    overflow: auto !important;

    &::-webkit-scrollbar {
      width: 0.2em;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    /*&:hover{
      overflow: auto !important;
    }*/
  }
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 2.5px !important;
  // padding: 8px;
}
