.detail-subscription {
  //padding:15px 0px;
  font-size: 0.85rem;
  .detail-subscription2 {
    display: flex;
    .logo {
      padding-right: 10px;
    }
    padding-bottom: 5px;
  }
  .first-card {
    padding: 15px 10px;
  }
  .second-card {
    padding: 15px 10px;
  }
  .toolbar-title {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 10px;
  }
  .left {
    margin-left: 25px !important;
  }
}

.css-2rde42-MuiButtonBase-root-MuiIconButton-root {
  padding: 1px !important;
  font-size: 0.5rem;

  // font-size: 1.125rem;
  // padding: 5px;
}
.css-1vooibu-MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;

  // width: 1em;
  // height: 1em;
}

.css-2rde42-MuiButtonBase-root-MuiIconButton-root:hover {
  background: green !important;
  color: white !important;
  // background-color: rgba(0, 0, 0, 0.04);
}

.css-y6rp3m-MuiButton-startIcon {
  margin-right: 2px !important;
}
