.css-i9sstl-MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}
.css-dw6aba-MuiInputBase-root-MuiOutlinedInput-root {
  height: 70px;
}

.css-ynikz8-MuiFormLabel-root-MuiInputLabel-root {
  margin-top: 7px;
}
