.subcription-list{
    .title{
        font-size: 25px;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        color:#504b4b;
        font-weight: 600;
    }
}
