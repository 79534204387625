@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

$circleSize: 200px;
// $circleSize: 165px;
$radius: 100px;
$shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
$fontColor: rgb(250, 250, 250);

.wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  .profile-pic {
    color: transparent;
    transition: all 0.3s ease;
    @include object-center;
    position: relative;
    transition: all 0.3s ease;
    // padding-top: 15px;
    // padding-bottom: 15px;

    height: $circleSize;
    width: $circleSize;
    border-radius: $radius;

    input {
      display: none;
    }

    img {
      position: absolute;
      object-fit: cover;
      width: $circleSize;
      height: $circleSize;
      box-shadow: $shadow;
      border-radius: $radius;
      z-index: 0;
    }

    .-label {
      cursor: pointer;
      height: $circleSize;
      width: $circleSize;
      border-radius: $radius;
    }

    &:hover {
      .-label {
        @include object-center;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 10000;
        color: $fontColor;
        transition: background-color 0.2s ease-in-out;
        border-radius: $radius;
        margin-bottom: 0;
      }
    }

    span {
      display: inline-flex;
      text-align: center;
      font-size: 12px;
      // padding: 0.2em;
      // height: 2em;
    }
  }
}
