.progress-bar{
    .bar{
        position: relative;
        background-color:  rgba(240,242,245,0.8);//#a6a6a6;
        height: 25px;
        min-width: 180px;
        border-radius: 20px;
        // border: 2px solid green;
        // box-shadow: 2px 2px 2px #333;
        padding: 1px 3px;
        .circle{
            position:absolute;
            height:35px;
            width: 35px;
            border-radius: 50%;
            background-color: rgba(240,242,245,1); //#a6a6a6;
            // border:3px solid green;
            top: -8px;
            left: -17px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 1px 1px 2px green;
            text-align: center;
            .star{
                // color: #2e7d32;
                //position: absolute;
                font-size: 30px;
                top: 0px;
                left: 0px;
                font-weight: 600;
                text-align: center;
            }
        }
        .indicator{
            // background-color: #2e7d32;
            height: 100%;
            width: 100%;
            border-radius: 10px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            .text{
                // color: white;
                font-size: 10px;
                font-weight: 600;
            }
        }
    }
}