.top-menu {
  //background-color: rgba($color: #f0f2f5, $alpha: 0.8);
  //background-color: rgba(#f0f2f5,0.8);
 
  background-color: #161b22; //rgba(#0f0f0f,0.8);
  display: flex;
  justify-items: center;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position:relative;
    
  }
  .btn {
    //color: #54656f;
  }
  .wrap-btn {
    padding-left: 5px;
    padding-right: 5px;
  }
  
}

