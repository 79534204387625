.left-drawer {
  font-family: "nunito";
  width: 100%;
  overflow: hidden;
  background-color: white;

  .container {
    padding: 0 !important;
  }
}
