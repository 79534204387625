.css-hnalh5-MuiBadge-badge {
  right: 50px !important;
  //   border: 1px solid red;
  border-radius: 5px !important;
}

.css-to4x1n-MuiBadge-badge {
  right: 50px !important;
  //   border: 1px solid red;
  border-radius: 5px !important;
}

.css-pwyk3z-MuiBadge-badge {
  right: 50px !important;
  //   border: 1px solid red;
  border-radius: 5px !important;
}

.css-46bh2p-MuiCardContent-root:last-child {
  padding-bottom: 10px !important;
  // padding-bottom: 26px;
}

.css-46bh2p-MuiCardContent-root {
  padding: 10px;
  // padding: 16px;
}

.css-vuvl5m-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #303f9f;
  border-width: 1px !important;
  /* border-width: 2px; */
}

.unpayBadge {
  //position: absolute;
/*   left: 2rem;
  top: -1rem;
  margin-left: 16.5rem; 
  display: inline;*/
  border-radius: 1rem;
 // background-color: #ff0800;
  color: #FFFFFFFF;
   padding-left: 0.5rem;
  padding-bottom: 0.2rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  font-size: 0.8rem;
}