.app-toolbar{
    //background-color: rgba(#f0f2f5, 0.8);
    height: 100%;
    .container{
        position: relative;
        
        .toolbar{
        padding-left: 15px;
        padding-right: 15px;
        /*padding-top: 20px;
        padding-bottom: 20px;*/
            display: flex;
            .logo{
                //color: #2e2e2e;
                display: flex;
                align-items: center;
                .title{
                    font-weight: 600;
                    font-size: "1rem";
                    font-family: 'Nunito';
                    margin-left: 10px;
                }
            }
            .content{
                //color: #2e2e2e;
                font-family: 'Nunito';
            }
        }
        .transparent-bg{
            width: 100%;
            height: 100%;
            background-color: rgba($color: #2e2e2e, $alpha: 0.02);
            position: absolute;
        }
    }
    
}