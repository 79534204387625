@keyframes moveToLeft {
  from {
    left: -300px;
  }
  to {
    left: 0px;
  }
}
.layout{
  display: flex;
  //border:1px solid red;
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
  .left-layout{
    //border:1px solid green;
    width: 25%;
    position: relative;
    .wrap-left-menu{
      display: flex;
      flex-direction: column;
      .top-menu{
        height: 60px;
      }
      .left-drawer{
        flex: 1 1 auto;
        //border: 1px solid green;
        height: calc(100vh - 60px);
        padding: 10px 10px;
      }
    }
    .wrap-display-box {
      position: absolute;
      top: 0;
      left: 0;
      background-color: white;
      width: 100%;
      z-index: 999;
      animation-name: moveToLeft;
      animation-duration: 0.5s;
    }
  }
  .contain-layout{
    //border: 1px solid black;
    flex: 1 1 auto;
    //background-color:rgba($color: #2e2e2e, $alpha: 0.09) ;
    //background-color: #444655;
    background-color: #1A1D21;
    height: "100%";
    max-width: calc(100vw - 25%);
    
  }
}
