.custom-button {
  color: #2639a3 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border: none !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  &:hover {
    border-radius: 5px !important;
    background-color: #3f51b5 !important;
  }
}
