.connexion-box{
    background: white;
    font-family: 'Nunito';
    color: black;
    font-weight: 500;
    height: 100vh;
    .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 100px);
        width: 100vw;
        color: #333;
        
        .container{
            max-width:500px;
            .title{
                text-align: center;
                padding-top : 10px;
                padding-bottom: 2px;
            }
            .subtitle{
                text-align: center;
                padding-top : 2px;
                padding-bottom: 2px;
            }
            .btn-connexion{
                padding-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                .btn{
                    font-family: 'Nunito';
                    font-weight: 400;
                }
                .btn-lg{
                    min-width: 100%;
                }
            }
        }
        .error{
            padding-top: 10px;
            color:red;
            font-family: 'nunito';
            font-style: normal;
        }
      }
      .section-back{
        display: flex;
        justify-content: center;
        text-decoration: none;
        
        //background-color: red;
        height: 50px;
      }
      .wrapper-2 {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        height: 100%;
        width: 100%;
        color: #333;
        .container{
            max-width:500px;
            .title{
                text-align: center;
                padding-top : 10px;
                padding-bottom: 2px;
                font-size: 18px; // New add
            }
            .subtitle{
                text-align: center;
                padding-top : 2px;
                padding-bottom: 2px;
            }
            .btn-connexion{
                padding-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                .btn{
                    font-family: 'Nunito';
                    font-weight: 400;
                }
                .btn-lg{
                    min-width: 100%;
                }
            }
        }
        .error{
            padding-top: 10px;
            color:red;
            font-family: 'nunito';
            font-style: normal;
        }
      }
}

.css-vms182-MuiListItem-root {
    margin-bottom: 10px;
    // border: 1px solid red;
}


.css-xdsdkw-MuiButtonBase-root-MuiListItemButton-root{
    padding: 5px !important;
    border: '1px solid red',
}
