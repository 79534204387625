.home-screen{
    //background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    height: 100%;
    .home-app-bar{
        height: 60px;
    }
    .content-home{
        height: calc(100% - 60px);
        //border: 1px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title{
            font-size: 1.2rem;
            text-align: center;
            padding-top: 35px;
            padding-bottom: 20px;
            //color:#41525d;
            font-weight: 600;
        }
        .description{
            font-size: 1rem;
            text-align: center;
            //color: #41525d;
            max-width: 500px;
            font-weight: 300;
        }
    }
}
